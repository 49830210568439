import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import {headers} from "@/api/headers";
import api from "@/api/configApi";
import router from "@/router/index"


export interface User {
  name: string;
  surname: string;
  email: string;
  password: string;
  api_token: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user;
    this.errors = {};
    window.localStorage.setItem('user_name', user.name);
    window.localStorage.setItem('user_email', user.email);
    window.localStorage.setItem('user_rol', user.rol);
    window.localStorage.setItem('access_token', user.token);
    window.localStorage.setItem('user_active', user.active);
    JwtService.saveToken(user.token);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    window.localStorage.removeItem('user_name');
    window.localStorage.removeItem('user_email');
    window.localStorage.removeItem('user_rol');
    window.localStorage.removeItem('user_active');
    JwtService.destroyToken();
  }

  @Action
  [Actions.LOGIN](credentials) {
  
    
    return ApiService.post("login", credentials)
      .then(({ data }) => { 
        if(data.status==200){
          this.context.commit(Mutations.SET_AUTH, data.data);  
        }else{
          this.context.commit(Mutations.SET_ERROR, data.message);
        }
      })
      .catch(({ response }) => {
         this.context.commit(Mutations.SET_ERROR, response.data.message);
      });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      
      })
      .catch(({ response }) => {
      
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post("forgot_password", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }


  
  @Action
  [Actions.VERIFY_AUTH](payload) { 

    if (JwtService.getToken()) {
        payload={ api_token:JwtService.getToken()};
        const headerslogin = {  "Accept": "application/json",     "Authorization": "Bearer " + JwtService.getToken()     };
        api.post('verify_token', payload , {headers:headerslogin})
      .then((res)=>{
          if(res.data.status==200){
            this.context.commit(Mutations.SET_AUTH, res.data.data);  
          }else{
            this.context.commit(Actions.LOGOUT);
            this.context.commit(Mutations.PURGE_AUTH);
          }    
        }      
      )
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        this.context.commit(Mutations.PURGE_AUTH);
      })

    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }


  }


}
