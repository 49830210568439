
import axios from 'axios'

//const configApi = 'https://api.ticketshop.com.co/api/'
const configApi = 'https://api-ad.centraltickets-ecuador.com/api/'

const api = axios.create({
    baseURL: configApi
})



export default api
